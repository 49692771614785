<template>
  <div class=" concent">
    <BaseForm
      :componentList="from"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      :type="type"
      class="formStyle"
      @handleFilter="newhandleFilter"
      @clearParams="clearParams"
      ref="fromdata"
    >
    <div slot="btn_after">
      <base-button icon='iconfont iconzhankai' class="foldBtn" :class="{PutawayBtn:fold}" type='default' @click="fold=!fold"></base-button>
    </div>
    </BaseForm>
    <Basetable
      :columns="columns"
      :showPage="true"
      :tableAttrs="{
        data: tableData,
        stripe: true,
      }"
      :key="type"
      ref="tableData"
      :webPage="true"
      @pageIndexChange="pageIndexChange"
      @pageSizeChange="pageSizeChange"
      class="main-page-table"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="action" slot-scope="scope">
        <IconButton  content="查看" icon="iconfont iconchakan" @click.prevent="jump(scope.row)"></IconButton>
      </template>
    </Basetable>
  </div>
</template>
<script>
import { getDictLists } from '@/filters/fromDict'
import BaseForm from '@/components/common/base-form//base-form'
import Basetable from '@/components/common/table/base-table/base-table'
import IconButton from '@/components/common/button/icon-button/icon-button'
import { fromconfig, columnsConfig } from './config.js'
import { workbenchApi } from '@/api/workbenchApi'
import { supplierApi } from '@/api/companyApi.js'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
export default {
  name: 'done',
  components: { BaseForm, Basetable, IconButton, BaseButton },
  data () {
    return {
      queryParas: {
        pageSize: 10,
        pageIndex: 1
      },
      type: 'YB',
      tableData: [],
      gysData: [],
      fold: false
    }
  },
  computed: {

    columns () {
      return columnsConfig(this)
    },
    from () {
      return fromconfig(this)
    },
    api () {
      return workbenchApi
    },
    ProcessNameList () {
      return getDictLists('PROCESS_NAME')
    }
  },
  methods: {
    /**
     * 路由跳转
     */
    jump (row) {
      console.log(row)
      if (row.actionUrl) {
        if (row.actionUrl.indexOf(';') !== -1) {
          row.actionUrl = row.actionUrl.split(';')[1]
        }
        this.$router.push({ path: row.actionUrl, query: { keyId: row.bizId, instanceId: row.instanceId } })
        row.businessId = row.bizId
        row.keyId = row.bizId
        sessionStorage.setItem('examine', JSON.stringify(row))
      }
    },
    // 获取供应商数据
    gysList () {
      supplierApi.getList().then(res => {
        if (res.success) {
          this.gysData = res.data
        }
      })
    },
    // 时间处理
    getdate (val) {
      return (
        new Date(val).getFullYear() +
        '-' +
        (new Date(val).getMonth() + 1) +
        '-' +
        new Date(val).getDate()
      )
    },
    // 重置
    clearParams () {
      this.queryParas = {
        pageSize: 10,
        pageIndex: 1
      }
    },
    newhandleFilter () {
      const params = {
        ...this.queryParas
      }
      params.pageIndex = 1
      this.queryParas.pageIndex = 1
      if (params.applicationdate) {
        params.startTime = this.getdate(params.applicationdate[0])
        params.endTime = this.getdate(params.applicationdate[1])
      }

      this.api.getDone(params).then((res) => {
        this.tableData = res.data.records
        this.$refs.tableData.total = res.data.records.length ? res.data.total : 0
      })
    },
    // 查询
    handleFilter () {
      const params = {
        ...this.queryParas
      }
      if (params.applicationdate) {
        params.startTime = this.getdate(params.applicationdate[0])
        params.endTime = this.getdate(params.applicationdate[1])
      }

      this.api.getDone(params).then((res) => {
        this.tableData = res.data.records
        this.$refs.tableData.total = res.data.total
      })
    },
    pageIndexChange (val) {
      this.queryParas.pageIndex = val
      this.handleFilter()
    },
    pageSizeChange (val) {
      this.queryParas.pageSize = val
      this.handleFilter()
    }
  },
  activated () {
    this.handleFilter()
    this.gysList()
  },
  mounted () {
    this.handleFilter()
    this.gysList()
  },
  created () { }
}
</script>
<style lang="scss" scoped>
.pd15 {
  padding: 15px;
}
.concent {
  height: calc(100% - 60px);
}
</style>
